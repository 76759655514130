// extracted by mini-css-extract-plugin
export var aboutMe = "BlogPost-module--aboutMe--2a9a8";
export var discussion = "BlogPost-module--discussion--81e38";
export var lead = "BlogPost-module--lead--a2400";
export var mainHeading = "BlogPost-module--mainHeading--43cdf";
export var metadata = "BlogPost-module--metadata--995e2";
export var post = "BlogPost-module--post--aa200";
export var postBody = "BlogPost-module--postBody--aa3b6";
export var readingTime = "BlogPost-module--readingTime--e0f40";
export var relatedPost = "BlogPost-module--relatedPost--883c7";
export var relatedPostWrapper = "BlogPost-module--relatedPostWrapper--c2c27";
export var topics = "BlogPost-module--topics--34652";
export var typo = "BlogPost-module--typo--1ffcf";
export var typoWrapper = "BlogPost-module--typoWrapper--1376f";